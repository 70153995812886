import React from "react"
import { Link } from "gatsby"
import { ConsumerAffairsForm } from "@tyson-foods/react-component-library"
import Layout from "../components/layout"
import { RevealButton } from "@tyson-foods/react-component-library"

import "../scss/contact-us.scss"
class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }
  async componentDidMount() {
    this.setState({ loaded: true })
  }
  content() {
    return (
      <Layout
        metaTitle="Contact Us"
        metaDescription="Contact us for questions or concerns regarding any specific product. Sign up to receive product updates, promotions, recipes, surveys and offers."
        metaKeywords="contact us, wright brand bacon, questions, concerns, requests"
      >
        <div className="image">
          <img
            className="contact-us-image"
            src="/ContactHero.jpg"
            alt="Contact Us"
          />
        </div>
         <div style={{ padding: "20px 10%" }}>
          <h1>Contact Us</h1>
          <ConsumerAffairsForm
            buttonColor="warning"
            formHandlerApiUri={process.env.GATSBY_FORM_HANDLER_API_URL}
            formHandlerApiKey={process.env.GATSBY_FORM_HANDLER_API_KEY}
          />
          <div
            style={{ textAlign: "center", paddingTop: "30px", color: "black" }}
          >
            <h2>Contact Us</h2>
            <p style={{ color: "black" }}>Call us at 1-800-227-5828</p>
            <p style={{ color: "black" }}>Tyson Consumer Relations</p>
            <p style={{ color: "black" }}>PO Box 2020</p>
            <p style={{ color: "black" }}>Springdale, AR 72765</p>
          </div>
        </div>
      </Layout>
    )
  }

  render() {
    return this.state.loaded ? this.content() : null
  }
}
export default ContactUs
